import { Modal } from "antd";
import { CustomerActions } from "../../../redux/Customer";
import { warningText } from "../common/msgText";

/**
 * @description getTag takes the order status and returns the color for tag
 */
export const getTag = (order_status: string) => {
    try {
        let status = order_status.toLowerCase()
        return status === "completed"
            ? "purple"
            : status === "delivered"
                ? "green"
                : status === "shipped"
                    ? "blue"
                    : status === "received"
                        ? "orange"
                        : status === "enroute"
                            ? "orange"
                            : "violet"
    } catch (error) {
        console.error(error);
    }
};

/**
 * @description getNotificationMSG based on order status
 */
export const getNotificationMSG = (orderstatus: string) => {
    try {
        let status = orderstatus.toLowerCase()
        return status === 'confirmed' ? "Your order has been " + status
            : status === 'enroute' ? "Your order is " + status :
                status === 'shipped' ? "Your order is " + status :
                    "Your order has been " + status
    } catch (error) {
        console.error(error);
    }
}
/**
 * @description get dummy Dealer Name if not present
 * @param dealer_name is sent as a param
 */
export const getDealerName = (name: string) => {
    return name ? name : 'John Doe'
}

 export const getDateAndTime = (timestamp: string) => {
    let date = new Date(timestamp);
    return date.toLocaleString('en-US');
  };

/**
 * @description getOptInData filters the data based on opt_in status
 * @params takes the allcustomers data from redux state
 */
export const getOptInData = (customerData: any) => {
    const optInData: any = []
    customerData.filter((a: any) => {
        if (a.notification_subscription_status === "OPT_IN") {
            optInData.push(a);
        }
    });
    return optInData
}

/**
* @description it display popup if empty search is made
* @params takes event as param
*/
export const isValid = (search: any, e: any) => {
    if (search.length === 0 && e === '') {
        popupNotification(warningText.invalidSearchTitle, warningText.emptySearch, ()=>{}, 'dispatch')
    }
}

/**
* @description set and resets values for the params sent and dispatches it 
* @params takes key name and dispatch from react hook
*/
export const changeTab = (key: any, dispatch: any) => {
    key === '1' ? dispatch(CustomerActions.resetActiveTab()) : dispatch(CustomerActions.setActiveTab())
}

/**
* @description loads and sets the initial state and counter 
* @params takes token , initialState, and timer function and function to set state
*/
export const loadApp = (token: any, allCustomers: any, counter: any, getCustomers: any) => {
    if (token && allCustomers.length <= 0) {
        //running counter to track inactivity
        counter()
        //get all orders data
        getCustomers(token);
    }
    //Prevents back button issue of sso   
    if (token) {
        window.history.pushState(null, window.location.href);
        window.onpopstate = function (event) {
            window.history.go(1);
        };
    }
}

/**
* @description gives error text popup when data not found when search is done
* @params takes title fpr popup, display the message and what action will be performed on click
*/
export const popupNotification = (title: string, message: string, action: any, type: string) => {    
    Modal.warning({
        title: title,
        content: (
            <div><p>{message}</p></div>
        ),
        okText: type !== '' ? "Ok" : 'Refresh',
        onOk() { action() },
    })
}
