import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/createStore'
import { CustomerActions } from '../redux/Customer';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { sortKey, warningText } from '../components/admin/common/msgText';
import { popupNotification } from '../components/admin/services/CustomerServices';

/**
 * @description custom hook which replaces old way of service calls. TODO: this should be removed
 * @returns 
 */
const useDashboard = () => {

    const dispatch = useDispatch();
    //calling auth hook to get idToken for sending in api for security
    const { userProfile } = useSelector((state: RootState) => state.Auth);
    const { idToken } = userProfile
    // redux state values
    const { allCustomers, filteredCustomers, isLoading, activeTab } = useSelector((state: RootState) => state.Customer);
    /**
     * @description fetches all users data fom server and store in redux state
     * @param filterText search string
     */
    const getCustomers = async (token: any) => {
        axios.get(`${process.env.REACT_APP_API}/customers`, {
            headers: {
                "APIkey": token
            }
        }).then((response) => {
            const result = response.data;
            const orderData = result.Items.length > 0 ? sortByProperty(result.Items, "timestamp").reverse() : result.Items;
            dispatch(CustomerActions.setIsLoading())
            dispatch(CustomerActions.setAllCustomers(orderData));
            dispatch(CustomerActions.setFilteredCustomers(orderData));
        }).catch((e: any) => {
            throw new Error(e)
        });
    }

    /**
   * @description fetches customers and analytics data for searched users
   */
    const searchCustomers = async (orderID: string) => {
        //dispatchMultiple(popupTitle.clickCount, dispatch)
        //check if empty or not              
        if (orderID === '') {
            // if user clear the search field the we need to display total data            
            dispatch(CustomerActions.resetFilteredCustomers());
        } else {
            if (!orderID.match(/^[0-9]+$/)) {
                //notify error  
                popupNotification(warningText.invalidSearchTitle, warningText.invalidSearchText, () => { }, 'popup')
            } else {
                dispatch(CustomerActions.resetIsLoading());
                await axios.get(`${process.env.REACT_APP_API}/orders`, {
                    params: {
                        search: orderID
                    },
                    headers: {
                        "APIkey": idToken
                    }
                }).then((response) => {
                    const { data } = response;
                    const { Items } = data
                    dispatch(CustomerActions.setIsLoading());
                    if (Items.length === 0) {
                        dispatch(CustomerActions.setFilteredCustomers(Items));
                        popupNotification(warningText.invalidSearchTitle, warningText.dataNotFoundText, () => { }, 'popup')
                    } else {
                        const orderData = Items.length > 0 ? sortByProperty(Items, sortKey) : Items;
                        dispatch(CustomerActions.setFilteredCustomers(orderData));
                    }
                }).catch((e: any) => {
                    throw new Error(e)
                });
            }

        }
    }
    /**
    * @description takes the response data and sorts the response based on the key passed
    * @returns sorted array of object in ascending order
    */
    const sortByProperty = (dataarray: any, key: any) => {
        if (dataarray.length > 0) {
            return dataarray.sort((prev: any, next: any) => {
                var firstObj = prev[key]; var secondObj = next[key];
                return ((firstObj < secondObj) ? -1 : ((firstObj > secondObj) ? 1 : 0));
            });
        }
    }
    /**
    * @description custom pagination for antd table --also handles ie and safari browser pagination design issue
    * @params takes the current page number , type of button click and the elements event state
    */
    const renderPagination = (current: any, type: any, originalElement: any) => {
        if (type === 'prev') {
            return <a className="table-prev" ><MdKeyboardArrowLeft /></a>;
        } if (type === 'next') {
            return <a className="table-next"><MdKeyboardArrowRight /></a>;
        }
        return originalElement;

    }
    return {
        allCustomers, filteredCustomers, getCustomers, searchCustomers, isLoading, renderPagination, activeTab
    }
}

export default useDashboard;