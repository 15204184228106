import Table from "antd/es/table/Table";
import Tag from "antd/es/tag"
import Column from "antd/lib/table/Column";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/createStore";
import { getDateAndTime, getDealerName, getTag } from "./services/CustomerServices";
import { Customer } from "./types/Customer";
import Moment from 'react-moment';
import useDashboard from "../../hooks/useDashboard";
import { Tooltip } from "antd";

/**
 * @description Opt In/ Opt Out tab component
 * @returns 
 */
const OptStatus = (props: any) => {

  // get redux state from customer
  const { filteredCustomers } = useSelector(
    (state: RootState) => state.Customer
  );

  //get render pagination function from the custom hook 
  const { renderPagination } = useDashboard();
  return (
    <>
      <Table key="opt_status_table"
        dataSource={filteredCustomers}
        pagination={{
          defaultPageSize: props.pagesize,
          itemRender: (current, type, originalElement) =>
            renderPagination(current, type, originalElement),
          className: 'pagination'
        }}>
        <Column
          title="Dealer / Customer Name"
          dataIndex="dealer_name"
          key="dealer_name"
          align="left"
         width="18%"
          render={(text, filteredCustomers: Customer) => getDealerName(text) + ' / ' + filteredCustomers.customer_first_name}
        />
        <Column
          title="Order #"
          dataIndex="order_id"
          key="order_id"
          align="left"
          width="10%"
          sorter={(a: Customer, b: Customer) =>
            parseInt(a.order_id) - parseInt(b.order_id)
          }
          sortDirections={["descend", "ascend"]}
          render={(text) => 
            <a target="_blank" href={`${process.env.REACT_APP_ORDER_URL}/#/order-status/${text}`}>
            <Tooltip title="Click to view order details">{text}</Tooltip></a> 
            }
        />
        <Column
          title="Order Status"
          dataIndex="status"
          key="status"
          align="left"
          render={(order_status: string) => <Tag color={getTag(order_status)} key={order_status}>
            {order_status.toUpperCase()}
          </Tag>}
        />
         
        <Column align="left" title="Opt In/ Opt Out" dataIndex="notification_subscription_status" key="notification_subscription_status" />
        <Column align="left" title="Sms/ Email" dataIndex="notification_channel" 
        key="notification_channel" 
        render={(notification_channel: string) =><p>{notification_channel.toUpperCase()}</p> }/>
        <Column align="left" title="Email Address" dataIndex="customer_email_address"
          key="customer_email_address"
          render={(customer_email_address: string,filteredCustomers: Customer) =>{
            return(
              filteredCustomers.notification_channel==="email" ? customer_email_address : null
            )}
          }
        />
      <Column
          title="Notification Date"
          dataIndex="timestamp"
          key="timestamp"
          align="left"
          render={(timestamp: string) =>  getDateAndTime(timestamp)}
        />
      </Table>
    </>
  );
};
export default OptStatus;
