import Table from "antd/es/table";
import Tag from "antd/es/tag"
import Column from "antd/lib/table/Column";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/createStore";
import { getDateAndTime, getDealerName, getNotificationMSG, getOptInData, getTag } from "./services/CustomerServices";
import "./styles/notification.css";
import { Customer } from "./types/Customer";
import Moment from 'react-moment';
import useDashboard from "../../hooks/useDashboard";
import Tooltip from "antd/es/tooltip";


/**
 * @description View All Notification  tab component
 * @returns 
 */
const NotificationList = (props: any) => {

  // get redux state from customer
  const { filteredCustomers } = useSelector(
    (state: RootState) => state.Customer
  );

  //get render pagination function from the custom hook 
  const { renderPagination } = useDashboard();
  return (
    <>
      <Table key="notification_table"
        dataSource={getOptInData(filteredCustomers)}
        pagination={{
          defaultPageSize: props.pagesize,
          itemRender: (current, type, originalElement) =>
            renderPagination(current, type, originalElement),
          className: 'pagination'
        }}>
        <Column
          title="Dealer / Customer Name"
          dataIndex='dealer_name'
          key="dealer_name"
          align="left"
          render={(text, filteredCustomers: Customer) => getDealerName(text) + ' / ' + filteredCustomers.customer_first_name}
        />
        <Column
          title="Order #"
          dataIndex="order_id"
          key="order_id"
          width="10%"
          align="left"
          sorter={(a: Customer, b: Customer) =>
            parseInt(a.order_id) - parseInt(b.order_id)
          }
          sortDirections={["descend", "ascend"]}
          render={(text) => 
          <a target="_blank" href={`${process.env.REACT_APP_ORDER_URL}/#/order-status/${text}`}>
            <Tooltip title="Click to view order details">{text}</Tooltip></a> 
            }
        />
        <Column
          title="Order Status"
          dataIndex="status"
          key="status"
          align="left"
          render={(status: string) => <Tag color={getTag(status)} key={status}>
            {status.toUpperCase()}
          </Tag>}
        />
        <Column
          title="Sms / Email"
          dataIndex="notification_channel"
          key="notification_channel"
          align="left"
          width="10%"
          className="notification"
           render={(notification_channel: string) =><p>{notification_channel.toUpperCase()}</p> }
        />
         <Column align="left" width="15%" title="Email Address" dataIndex="customer_email_address"
          key="customer_email_address"
          render={(customer_email_address: string,filteredCustomers: Customer) =>{
            return(
              filteredCustomers.notification_channel==="email" ? customer_email_address : null
            )}
          }
        />
        <Column title="Message" dataIndex="status"
          key="status"
          align="left"
          render={(status: string) => getNotificationMSG(status)}
        />
        <Column
          title="Notification Date"
          dataIndex="timestamp"
          key="timestamp"
          align="left"
          render={(timestamp: string) =>  getDateAndTime(timestamp)}
        />
      </Table>
    </>
  )
};
export default NotificationList;
